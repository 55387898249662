import AppTable from '../../AppTable';
import { Button, Row, Col, notification } from 'antd';
import OrganizationsActionsList from './OrganizationsActionsList';
import { useEffect, useState } from 'react';
import useOrganization from '../../../core/hooks/UseOrganizations';
import AppLoading from '../../AppLoading';
import OrganizationsService from '../../../services/OrganizationsService';
import OrganizationModal from './OrganizationModal';
import { useAppSelector } from '../../../redux/hooks';
import { OrganizationInterface } from '../../../types/Organization';

interface OrganizationInfoProps {
    testId?: string;
}

const OrganizationInfo = ({
    testId = 'OrganizationInfo',
}: OrganizationInfoProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loading, refresh, organization } = useOrganization();
    const [isAddingOrganization, setIsAddingOrganization] =
        useState<boolean>(false);
    const { user } = useAppSelector((state) => state.user);
    const [isAddOrganizationModalVisible, setIsAddOrganizationModalVisible] =
        useState<boolean>(false);

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name', align: 'left' },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (_: string, record: OrganizationInterface) => (
                <>
                    <OrganizationsActionsList
                        record={record}
                        refresh={refresh}
                    />
                </>
            ),
        },
    ];

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const AddNewOrganization = async (org: { name: string }) => {
        setIsAddingOrganization(true);
        try {
            await OrganizationsService.addNewOrganization({
                id: `${new Date().getTime()}`,
                name: org.name,
            });
            notification.success({
                message: 'Organization Added',
                description: 'The organization has been added successfully.',
            });
            setIsLoading(true);
        } catch (e: any) {
            notification.error({
                message: 'Organization Add Failed',
                description: 'There was an error adding the organization.',
            });
        } finally {
            refresh();
            setIsAddingOrganization(false);
            setIsAddOrganizationModalVisible(false);
            setIsLoading(false);
        }
    };

    const handleAddOrganization = () => {
        setIsAddOrganizationModalVisible(true);
    };

    return isLoading ? (
        <AppLoading />
    ) : (
        <div data-testid={testId}>
            <Row justify="space-between" style={{ marginBottom: '16px' }}>
                <Col>
                    <strong>Organization Info</strong>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleAddOrganization}>
                        Add Organization
                    </Button>
                </Col>
            </Row>
            <AppTable
                columns={columns}
                data={organization?.organizations || []}
                rowKey="key"
                pagination={{ pageSize: 10 }}
            />
            <OrganizationModal
                record={organization}
                visible={isAddOrganizationModalVisible}
                onClose={() => setIsAddOrganizationModalVisible(false)}
                onSubmit={AddNewOrganization}
                isEditing={false}
                isLoading={isAddingOrganization}
                testId="OrganizationModal"
            />
        </div>
    );
};

export default OrganizationInfo;
